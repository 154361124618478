export const PROD_MASTER_TABLE = "Product_Master_New";
export const PRICE_TABLE = "Price_Details";
export const SEARCH_PRODUCTS = "Search Product";
export const REGION_CHANGE_ALL = "Region Change All";
export const REGION_CHANGE_BOM = "Region Change Mumbai";
export const REGION_CHANGE_PUNE = "Region Change Pune";
export const REGION_CHANGE_S = "Region Change YoGrocery";
export const REGION_CHANGE_DF = "Region Change DF";
export const EDIT_PRODUCTS = "Edit Products";
export const CLOSE_SCREEN = "Close Screen";
export const UPDATE_DATA_IN_DB = "Update Data in DB";
export const ADD_PRODUCTS = "Add Products";
export const BULK_UPLOADS = "Bulk Uploads";
export const FINANCE_TEXT = "finance";
export const COMMERCIAL_TEXT = "commercial";
export const ADMIN_TEXT = "admin";
export const CITY_CHANGE = "City Change";
export const INIT_PAGE_TITLE = "Pricing Portal";
export const SESSION_TEXT = "SZ User Status";
export const SESSION_AUTHENTICATED_EMAIL = "Logged User Email Id";
export const LOGGED_USER_EMAIL = "Logged User Email Id";
export const LOGGED_USER_PASSWORD = "Logged User Password";
export const LOGGED_USER_TYPE = "Logged User Type";
export const LOGGED_CITY = "Logged User City";
export const MATERIAL_TEXT = "Material";
export const WASTAGE_TEXT = "Wastage";
export const CITY_ALL = "All Cities";
export const CITY_MUMBAI = "Mumbai City";
export const CITY_PUNE = "Pune City";
export const SERIES_S = "Series S";
export const REGION_BOM_A = "A";
export const REGION_BOM_B = "B";
export const REGION_BOM_C = "C";
export const REGION_BOM_CP = "CP";
export const REGION_BOM_N = "N";
export const REGION_BOM_AA = "AA";
export const REGION_BOM_BB = "BB";
export const REGION_BOM_NN = "NN";
export const  REGION_BOM_DD = "DD";
export const  REGION_BOM_DE = "DE";
export const  REGION_BOM_DF = "DF";
export const  REGION_BOM_DG = "DG";
export const  REGION_BOM_RG = "RG";
export const  REGION_BOM_D = "D";
export const REGION_BOM_PG = "PG";
export const REGION_BOM_ST = "ST";
export const REGION_BOM_NK = "NK";
export const REGION_BOM_H = "H";
export const REGION_BOM_F = "F";
export const REGION_BOM_FD = "FD";
export const REGION_BOM_K = "K";
export const REGION_BOM_M = "M";
export const REGION_BOM_MT = "MT";
export const REGION_PUNE_P = "P";
export const REGION_BOM_O = "O";
export const REGION_S = "S";

export const ALL_REGIONS_LIST = [
  REGION_BOM_A,
  REGION_BOM_B,
  REGION_BOM_C,
  REGION_BOM_D,
  REGION_BOM_CP,
  REGION_BOM_MT,
  REGION_BOM_O,
  REGION_BOM_N,
  REGION_BOM_NK,
  REGION_BOM_K,
  REGION_S
];

export const ALL_PRODUCT_EDIT_LIST = [
  REGION_BOM_A,
  REGION_BOM_B,
  REGION_BOM_C,
  REGION_BOM_F,
  REGION_BOM_FD,
  REGION_BOM_M,
  REGION_BOM_D,
  REGION_BOM_CP,
  REGION_BOM_MT,
  REGION_BOM_O,
  REGION_BOM_N,
  REGION_BOM_NK,
  REGION_BOM_K,
  REGION_S
];

export const ALL_OFFERS_REGION_LIST = [
  REGION_BOM_A,
  REGION_BOM_B,
  REGION_BOM_C,
  REGION_BOM_CP,
  REGION_BOM_N,
  REGION_BOM_NK,
  REGION_BOM_K,
  REGION_BOM_F,
];


export const ALL_REGIONS_DD = [
  { value: "A", label: "Series A"},
  { value: "D", label: "Series D"},
  { value: "B", label: "Series B"},
  { value: "O", label: "Series O"},
  { value: "CP", label: "Series CP"},
  { value: "K", label: "Series K"},
  { value: "NK", label: "Series NK"},
  { value: "C", label: "Series C"},
  { value: "S", label: "Series S"},
  { value: "N", label: "Series N"},
  { value: "T", label: "Series T"}
];

export const ALL_REGIONS_EXCEPT_A = [
  { value: "D", label: "Series D"},
  { value: "B", label: "Series B"},
  { value: "O", label: "Series O"},
  { value: "CP", label: "Series CP"},
  { value: "K", label: "Series K"},
  { value: "NK", label: "Series NK"},
  { value: "C", label: "Series C"},
  { value: "S", label: "Series S"},
  { value: "N", label: "Series N"}
];

export const ALL_REGION_DD = [
  { value: "A", label: "A", title:""},
  { value: "B", label: "B", title:""},
  { value: "O", label: "O", title:""},
  { value: "N", label: "N", title:""},
  { value: "K", label: "K", title:""},
  { value: "D", label: "D", title:""},
  { value: "CP", label: "CP", title:""},
  { value: "S", label: "S",title:""},
  { value: "NK", label: "NK",title:""},
  { value: "C", label: "C",title:""}
];

export const ALL_OFFERS = [
  { value: "A", label: "Series A"},
  { value: "B", label: "Series B"},
  { value: "K", label: "Series K"},
  { value: "NK", label: "Series NK"},
  { value: "C", label: "Series C"},
  { value: "CP", label: "Series CP"},
  { value: "F", label: "Series F"},
  { value: "N", label: "Series N"}
];

export const PRICE_SERIES = [
  { value: "A", label: "Series A"},
  { value: "N", label: "Series N"},
  { value: "O", label: "Series O"},
  { value: "K", label: "Series K"},
  { value: "CP", label: "Series CP"},
  { value: "B", label: "Series B"},
  { value: "NK", label: "Series NK"},
  { value: "C", label: "Series C"},
];

export const RETAILER_REGIONS_DD = [
  {value :"ALL",label:"ALL"},
  { value: "D", label: "Series D"},
  { value: "O", label: "Series O"},
  { value: "CP", label: "Series CP"},
  { value: "RG", label: "Series RG"},
  { value: "B", label: "Series B"},
  { value: "K", label: "Series K"},
  { value: "NK", label: "Series NK"},
  { value: "PG", label: "Series PG"},
  { value: "C", label: "Series C"},
  { value: "S", label: "Series S"},
  { value: "N", label: "Series N"}
];


export const REGION_YO_GROCERY = "YoGrocery";
export const REGION_BOM_ALL = "ALL_B";
export const REGION_ALL = "ALL";
export const TRANSPORT_FACTOR = {
  P: 1,
  N: 1,
  A: 1,
  D: 1,
  F: 1,
  FD: 1,
  M: 1,
  MT: 1,
  RG: 3.5,
  S: 1,
  AA: 1,
  DD:2.0,
  DG:3.5,
  O: 1,
  B: 1.5,
  BB: 1.5,
  PG: 1,
  NK: 2,
  ST: 1,
  C: 1,
  CP: 1,
  H: 1,
  K: 1,
};



export const LOCATIONOPTIONS_INVENTORY = [
  { header: 'A1-A19', options: ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10', 'A11', 'A12', 'A13', 'A14', 'A15', 'A16', 'A17', 'A18', 'A19'] },
  { header: 'B1-B19', options: ['B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9', 'B10', 'B11', 'B12', 'B13', 'B14', 'B15', 'B16', 'B17', 'B18', 'B19'] },
  { header: 'C1-C19', options: ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10', 'C11', 'C12', 'C13', 'C14', 'C15', 'C16', 'C17', 'C18', 'C19'] },
  { header: 'D1-D19', options: ['D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9', 'D10', 'D11', 'D12', 'D13', 'D14', 'D15', 'D16', 'D17', 'D18', 'D19'] }
];

export const IMAGE_FOLDER = process.env.REACT_APP_IMAGE_FOLDER || "superzop_ordering_offers_images";
//development

export const BASE_URL = process.env.REACT_APP_ORDERING_BACKEND_URL || 'http://dev-services.superzop.com';
// export const BASE_URL = 'http://localhost:3003';
export const BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL || 'https://firebasestorage.googleapis.com/v0/b/superzop-ordering-development.appspot.com/o/superzop_ordering_offers_images%2F';
export const IMAGE_TOKEN = process.env.REACT_APP_IMAGE_TOKEN || '?alt=media&token=eb182f41-faf1-49f4-b61e-3c303fa279c3';
export const API_TOKEN = process.env.REACT_APP_API_TOKEN || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTU0OWIwMTIwMWUyZjMzZWE3NmFkZjYiLCJlbWFpbCI6InNtdHdpbmtsZTQ1MkBnbWFpbC5jb20iLCJpYXQiOjE2MzI5MzQ2NTgsImV4cCI6MTYzMjkzODI1OH0._oHr3REme2pjDDdRliArAeVG_HuimbdM5suTw8HI7ut';


//production
//export const BASE_IMAGE_URL =
  //"https://firebasestorage.googleapis.com/v0/b/superzop-ordering-live.appspot.com/o/superzop_ordering_offers_images%2F";
//export const IMAGE_TOKEN =
  //"?alt=media&token=e16e49a5-5ad3-4e0f-9d0b-9741b9b3c6ed";
