import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumb,
  Dropdown,
  DropdownButton,
  Alert,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Button,
  Form,
  Modal,
  Badge,
  Navbar,
} from "react-bootstrap";
import { toast } from "react-toastify";
import database from "../../service/firebaseConfig";
import { FaWindows, FaLessThanEqual } from "react-icons/fa";
import Select from "react-select";
import Creatable from "react-select/creatable";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import UserEmail from "../../CommonRequirements/FetchUserEmailId";
import UpdatedDate from "../../CommonRequirements/UpdatedDate";
import UpdatedTime from "../../CommonRequirements/UpdatedTime";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import baseUrl from "../../service/servicesConfig";

const ProductDumpDetails = database.database().ref(`Product_Dump`);
const Product_Base = database.database().ref(`Product_Base_Data`);
const SuppliersDB = database.database().ref(`Suppliers`);
const options = [
  // { value: 'This button is Diabled', label: 'This Select is Diabled' },
  // { value: 'option2', label: 'Click on proceed first' },
];
const initialValue = [options[0], options[2]];

function AddProductDump() {
  const [addNewRecord, setAddNewRecord] = useState(false);
  const [submitDetails, setSubmitDetails] = useState(false);
  const [Variety, setVariety] = useState()
  const [dumpDate, setProductDumpDate] = useState("");
  const [category, setCategory] = useState("");
  const [articleCode, setArticleCode] = useState("");
  const [itemName, setItemName] = useState("");
  const [qtyDumped, setQtyDumped] = useState("");
  const [Outersize, setOutersize] = useState("");
  const [bagPurchased, setBagPurchased] = useState("");
  const [totalWeight, setTotalWeight] = useState("");
  const [eachPurchaseData, setEachPurchaseData] = useState("");
  const [productBaseData, setProductBaseData] = useState({});
  const [articleInfo, setArticleInfo] = useState("");
  const [articleOptions, setArticleOptions] = useState("");
  const [disableList, setDisableList] = useState(false);
  const [dateToModify, setDateToModify] = useState(new Date());
  const [validated, setValidated] = useState(false);
  const [dumpReason, setDumpReason] = useState("");
  const [salvageRate, setSalvageRate] = useState("");
  const [salvageAmt, setSalvageAmt] = useState("");
  const [lossAmt, setLossAmt] = useState("");
  const [uomValue, setUomValue] = useState("");
  const [normalRate, setNormalRate] = useState("");
  const [qrdValue, setQrdValue] = useState([]);
  const [QrIdOpt, setQrIdOpt] = useState([]);
  const [BagQrd_2, setBagQrd_2] = useState([]);
  const [partialBagQrdValues, setpartialBagQrdValues] = useState([]);
  const [BagQrdValues, setBagQrdValues] = useState([]);
  const [proceed, setProceedButton] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialValue);

  

  let user_email = sessionStorage.getItem("Logged User Email Id");

  const PurchaseData = [];
  const DateofPurchase = [];
  let purchaseItems = {};
  const categoryField = useRef();
  const itemNoField = useRef();
  const itemNameField = useRef();
  const totalWtPurchasedRef = useRef();
  const datepickerRef = useRef();
  const quantityDumpedRef = useRef();
  let btnRef = useRef();
  const salvageRateRef = useRef();

  const ListOfReasons = [
    { value: "Damaged/Spoilt Product", label: "Damaged/Spoilt Product" },
    { value: "Expired Product", label: "Expired Product" },
    { value: "Wet Product", label: "Wet Product" },
    { value: "Infestation", label: "Infestation" },
    { value: "Bad Quality", label: "Bad Quality" },
  ];


   // Simulating Fake Disable select since disabled property is not working with select component
   const disabledStyle = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#e9ecef',
      borderColor: '#ced4da',
      color: '#6c757d',
      cursor: 'not-allowed',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#6c757d',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#e9ecef' : provided.backgroundColor,
      color: state.isSelected ? '#6c757d' : provided.color,
      cursor: 'not-allowed',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#e9ecef',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#6c757d',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#6c757d',
      cursor: 'not-allowed',
    }),
    options: (provided) => ({
      ...provided,
      backgroundColor: '#e9ecef',
    }),
  };

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const notifyUser = (success) => {
    if (success) {
      return toast("Product Dump Details added", { type: toast.TYPE.SUCCESS });
    }
    return toast("Couldn't add the Product Dump Details", {
      type: toast.TYPE.ERROR,
    });
  };

  const fetchProductItem = () => {
    const url = `/api/superzop/pricelist/price-details`;

    Promise.all([Product_Base.once("value"), baseUrl.get(url)]).then(
      ([snapShot, priceSnap]) => {
        const productItems = snapShot.val();
        const priceItems = priceSnap.data.data || {};
       
        let priceMap = {};
        Object.keys(priceItems).forEach((productId) => {
          priceMap[productId] = Number(priceItems[productId].final_buying_price) || 0;
        });

        const productBaseInfo = [];
        Object.keys(productItems).forEach((productId) => {
          // if (productItems[productId]["buying_flag"] == 'Y') {
          productBaseInfo.push({
            articleId: productId,
            category: productItems[productId].category,
            uom: productItems[productId].uom,
            lot_size: productItems[productId].lot_size
              ? productItems[productId].lot_size
              : 0,
            brand: productItems[productId].brand,
            variety: productItems[productId].variety,
            itemName: productItems[productId].long_description,
            normal_rate: Number(priceMap[productId]) || 0,
            outer_size : productItems[productId]?.outer_size || 0

          });
          // }
        });

        setArticleInfo(productBaseInfo);
      }
    );
  };

  useEffect(() => {
    fetchProductItem();
  }, []);

  const populateArticle = () => {
    const allOptionsArticle = [];
    articleInfo.forEach((item) => {
      let itemName = item.itemName;
      if (item.brand) itemName = item.brand + " - " + item.itemName;

      allOptionsArticle.push({ value: itemName, label: itemName , 
        variety : item.variety});
    });
    setArticleOptions(allOptionsArticle);

  };

  useEffect(() => {
    if (articleInfo) populateArticle();
  }, [articleInfo]);

  const insertDomData = (itemValue, articleCode) => {
    let article_Id = null;
    setQrIdOpt([]);
    setQrdValue([]);


    if (articleCode) {
      setDisableList(false);

      articleInfo.forEach((item) => {
        if (articleCode.includes(item.articleId)) {
          setDisableList(true);
          categoryField.current.value = item.category;
          let itemValue = item.itemName;
          if (item.brand) itemValue = item.brand + " - " + item.itemName;

          article_Id = articleCode;
          setCategory(item.category);
          setItemName(itemValue);
          setUomValue(item.uom);
          quantityDumpedRef.current.value = item.lot_size ? item.lot_size : 1;
          setQtyDumped(Number(item.lot_size || 1));
          setNormalRate(Number(item.normal_rate));
          setOutersize(item.outer_size)
          setVariety(item.variety)
        }
      });
    } else if (itemValue) {
      articleInfo.forEach((item) => {
        let itemName = item.itemName;
        if (item.brand) itemName = item.brand + " - " + item.itemName;

        if (itemValue == itemName) {
          article_Id = item.articleId;
          setCategory(item.category);
          categoryField.current.value = item.category;
          quantityDumpedRef.current.value = item.lot_size ? item.lot_size : 1;
          setQtyDumped(Number(item.lot_size || 1));
          setNormalRate(Number(item.normal_rate));
          setArticleCode(item.articleId);
          itemNoField.current.value = item.articleId;
          setUomValue(item.uom);
          setOutersize(item.outer_size)
          setVariety(item.variety)

        }
      });
    }

    const url = `/api/superzop/get-qr-id?item_id=${article_Id}`;

    baseUrl
      .get(url)
      .then((res) => {

        console.log(res.data.data.qr_id );
        if (res.data.data.qr_id.length > 0) {
          let opt = res.data.data.qr_id.map((item) => {
            return {
              value: item,
              label: item,
            };
          });
          setQrIdOpt(opt);
          toast("Qr ids added ", { type: toast.TYPE.SUCCESS });
        } else {
          toast("Not found any Qr id for this item ", {
            type: toast.TYPE.ERROR,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast("Not found any Qr id for this item ", { type: toast.TYPE.ERROR });
      });
  };



  const PushProductDump = async (event) => {

    let todaysDate = "";
    let currentMn = "";
    let currentDt = "";
    const form = event.currentTarget;
    event.preventDefault();
  
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (dumpReason) {
        let productDumpDate = dumpDate;
  
        if (
          articleCode !== "" ||
          (itemName !== "" && uomValue !== "" && salvageRate !== "")
        ) {
          if (uomValue !== "") {
            const randomKey =
              articleCode + "-" + Math.floor(Math.random() * 100 + 1); //itemKey
  
            if (dumpDate == "") {
              if (dateToModify.getDate() < 10) {
                currentDt = "0" + dateToModify.getDate();
              } else {
                currentDt = dateToModify.getDate();
              }
              if (dateToModify.getMonth() + 1 < 10)
                currentMn = "0" + (dateToModify.getMonth() + 1);
              else {
                currentMn = dateToModify.getMonth() + 1;
              }
              productDumpDate = `${currentDt}-${currentMn}-${dateToModify.getFullYear()}`;
            }
  
            const productDumpDt = new Date(
              productDumpDate.split("-")[2],
              productDumpDate.split("-")[1],
              productDumpDate.split("-")[0]
            );
            const year = productDumpDt.getFullYear();
            const month =
              productDumpDt.getMonth().toString().length === 1
                ? `0${productDumpDt.getMonth()}`
                : productDumpDt.getMonth();
            const date =
              productDumpDt.getDate().toString().length === 1
                ? `0${productDumpDt.getDate()}`
                : productDumpDt.getDate();
  
            const product_dump_date_format = `${year}-${month}-${date}`;
  
            const currentDateObj = new Date();
  
            const currYear = currentDateObj.getFullYear();
            const currMonth =
              currentDateObj.getMonth().toString().length === 1
                ? `0${currentDateObj.getMonth() + 1}`
                : currentDateObj.getMonth() + 1;
            const currDate =
              currentDateObj.getDate().toString().length === 1
                ? `0${currentDateObj.getDate()}`
                : currentDateObj.getDate();
  
            let fourDaysOlderDate = new Date(
              `${currYear}-${currMonth}-${currDate}`
            );
            fourDaysOlderDate = fourDaysOlderDate.setDate(
              fourDaysOlderDate.getDate() - 4
            );
  
            
            // if((Variety == 'GONI' || Variety == 'BAG' ) && qrdValue.length == 0){
            //   return toast("QR id is required for this item ", {
            //     type: toast.TYPE.ERROR,
            //   });
            // }
       
            let result 
            if( qrdValue.length !== 0 ){

              if (qrdValue.length !== 0 && qrdValue.length < Math.ceil(qtyDumped / Outersize)) {
                console.log(qrdValue.length ,  Math.ceil(qtyDumped/Outersize) , qtyDumped, Outersize);
                return toast("QR id count should not be less then ceiling of dumped qty in kg / outer size", {
                  type: toast.TYPE.ERROR,
                });
              }

              const qur = `/api/superzop/validate-qr/validateQRCodeForUpdate`;
              try {
                //  result = await baseUrl.post(qur, { qr_id: qrdValue, item_id: articleCode });
                 result = await baseUrl.post(qur, { qr_id: qrdValue, item_id: articleCode , 
                  qr_ids:(() => {                     //IIFE function
                  const itemList = [];
                  for (let ids of qrdValue) {
                    itemList.push({
                      qr_id: ids,
                      is_fully_converted: 1
                    });
                  }
    
                  // For partially converted
                  for (let ids of BagQrd_2) {
                  if (!qrdValue.includes(ids)){
                      itemList.push({
                        qr_id: ids,
                        is_fully_converted: 0
                      });
                  }
                  }
    
                  return itemList;
                })()});
        
                let list = result.data.qrObj;
                let list_invalid = []
                for (let obj in list) {
                  if (list[obj][0].isValid == false) {
                    list_invalid.push(obj)
                  }
                }
    
                if (list_invalid.length > 0) {
                  return toast(`${list_invalid.join(', ')} QR ids are invalid select a different id `, {
                    type: toast.TYPE.ERROR,
                  });
                } else {
                  toast(`Successfully added QR ids `, {
                    type: toast.TYPE.SUCCESS,
                  });
                }

                if (result.data.isValid == false ) {
                  return toast(`${result.data.message}`, {
                    type: toast.TYPE.ERROR,
                  });
                }
  
              } catch (error) {
                console.log(error);
                btnRef.current.removeAttribute("disabled");
                return toast("QRCode not validate", { type: toast.TYPE.ERROR });
              }

            }


              btnRef.current.setAttribute("disabled", "disabled");

              const dataObj = {
                dump_date: productDumpDate,
                category: category,
                article_code: articleCode,
                item_name: itemName,
                uom: uomValue,
                qty_dumped: qtyDumped,
                normal_rate: normalRate,
                salvage_rate: salvageRate,
                salvage_amt: salvageAmt,
                reason: dumpReason,
                unique_key: `${productDumpDate}-${randomKey}`,
                created_by: UserEmail(),
                created_at: `${UpdatedDate()} ${UpdatedTime()}`,

                qr_id: qrdValue,
                dump: productDumpDate,
                dump_user: user_email,
                delivery_date: productDumpDate,
              };

              dataObj["qr_ids"]= (() => {
                const itemList = [];
                for (let ids of qrdValue) {
                  itemList.push({
                    qr_id: ids,
                    is_fully_converted: 1
                  });
                }
      
                // For partially converted
                for (let ids of BagQrd_2) {
                  if (!qrdValue.includes(ids)){
                    itemList.push({
                      qr_id: ids,
                      is_fully_converted: 0
                    });
               }
                }
      
                return itemList;
              })()
      

              console.log('-------------------');
              console.log(dataObj);
              console.log('-------------------');

              
              const createFirebaseProm = new Promise((resolve, reject) => {
                ProductDumpDetails.child(
                  `${productDumpDate.split("-")[2]}/${
                    productDumpDate.split("-")[1]
                  }/${productDumpDate}`
                )
                  .child(randomKey)
                  .update(dataObj)
                  .then(() => {
                    resolve("Success");
                  })
                  .catch((error) => {
                    reject("Failed");
                  });
              });

              const createSqlProm = new Promise((resolve, reject) => {
                const url =
                  "/api/superzop/commercial/productdump/createproductdumprecord";

                baseUrl
                  .post(url, dataObj)
                  .then((res) => {
                    if (res.status === 200) {
                      resolve("Success");
                    } else {
                      reject("Failed");
                    }
                  })
                  .catch((error) => {
                    reject("Failed");
                  });
              });

              await Promise.all([createFirebaseProm, createSqlProm]);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
              notifyUser(true);
            




            
          } else {
            return toast("Select Uom Value", { type: toast.TYPE.ERROR });
          }
        } else {
          return toast("Enter all fields ", { type: toast.TYPE.ERROR });
        }
      } else {
        return toast("Select Dump Reason", { type: toast.TYPE.ERROR });
      }
    }

    setValidated(true);
  };
  

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black" }}
    />
  );

  const dumpList = () => {
    window.open("#/productDumpList");
  };

  return (
    <>
      <NavBar />
      <Form noValidate validated={validated} onSubmit={PushProductDump}>
        <Navbar
          className="bg-light mr-auto justify-content-between"
          style={{ paddingTop: 100 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Badge variant="dark">Create Product Dump Details </Badge>
          </div>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              dumpList();
            }}
          >
            List Dump Details
          </Button>
        </Navbar>
        {articleInfo == "" ? (
          <Loader
            type="TailSpin"
            color="#00BFFF"
            height={50}
            width={50}
            style={{ display: "flex", justifyContent: "center" }}
            timeout={10000} //20 secs
          />
        ) : (
          <div>
            <Table size="sm">
              <tr>
                <td>Dump Date</td>
                <td>
                  <DatePicker
                    ref={datepickerRef}
                    required
                    selected={dateToModify}
                    onChange={(val) => {
                      setDateToModify(val);
                      setProductDumpDate(
                        `${
                          val.getDate() < 10
                            ? "0" + val.getDate()
                            : val.getDate()
                        }-${
                          val.getMonth() + 1 < 10
                            ? "0" + (val.getMonth() + 1)
                            : val.getMonth() + 1
                        }-${val.getFullYear()}`
                      );
                    }}
                    minDate={new Date()}
                    maxDate={new Date()}
                    aria-describedby="basic-addon1"
                    dateFormat="dd/MM/yyyy"
                    customInput={<CustomDateInput />}
                  />
                </td>
              </tr>
              <tr>
                <td>Item Name</td>
                <td>
                  {articleCode == "" || disableList == false ? (
                    <Select
                      required
                      options={articleOptions}
                      ref={itemNameField}
                      onChange={(val) => {
                        setItemName(val.value);
                        let itemName = val.value;
                        let itemCode = null;
                        insertDomData(itemName, itemCode);
                      }}
                      style={{ width: "150px" }}
                    />
                  ) : (
                    <Form.Control
                      ref={itemNameField}
                      value={itemName}
                      readOnly
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Article Code</td>
                <td>
                  {itemName == "" || disableList ? (
                    <Form.Control
                      ref={itemNoField}
                      onChange={(e) => {
                        setArticleCode(e.target.value);
                        let itemCode = e.target.value;
                        let itemName = null;
                        insertDomData(itemName, itemCode);
                      }}
                    />
                  ) : (
                    <Form.Control
                      ref={itemNoField}
                      onChange={(e) => {
                        setArticleCode(e.target.value);
                        let itemCode = e.target.value;
                        let itemName = null;
                        insertDomData(itemName, itemCode);
                      }}
                      readOnly
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Category</td>
                <td>
                  <Form.Control ref={categoryField} readOnly />
                </td>
              </tr>

              <tr>
                <td>UOM</td>
                <td>
                  <Form.Control
                    value={uomValue}
                    readOnly
                    onChange={(e) => {
                      setUomValue(e.target.value);
                    }}
                  />
                </td>
              </tr>
                {(Variety == 'GONI' || Variety == 'BAG' ) && (
                   <tr>
                   <td>Bag QR ID</td>
                   <td>
                     <Select
                       isMulti
                       onChange={(selectedOptions) => {
                         setQrdValue(
                           selectedOptions
                             ? selectedOptions.map((option) => option.value)
                             : []
                         );
                         setBagQrdValues(selectedOptions ? selectedOptions : []);
                       }}
                       options={QrIdOpt}
                       value={BagQrdValues}
                     ></Select>
                   </td>
                   <td><Button onClick={(e)=>{setProceedButton(true)}}>Proceed</Button></td>
                 </tr>
                )}
             
             

              <tr>
                 <td> Bag QR ID - Partially Converted </td>
              <td>
                       {proceed ? (
                        <Select
                        isMulti
                        onChange={(e) => {
                        setBagQrd_2(e ? e.map((option) => option.value) : []);
                        setpartialBagQrdValues(e ? e : []);
                        }}
                      options={QrIdOpt.filter((FqrId) => !BagQrdValues.some((QrId) => QrId === FqrId))}
                      value={partialBagQrdValues.filter((FqrId) => !BagQrdValues.some((QrId) => QrId === FqrId))}
                    />
                ) : (
                <Select
                isMulti
                readOnly
                styles={disabledStyle}
                options={options}
                value={selectedValue}
                />
              )}
              </td>
            </tr>
              <tr>
                <td>Qty Dumped ({  uomValue }) </td>
                <td>
                  <Form.Control
                    ref={quantityDumpedRef}
                    required
                    onChange={(e) => {
                      setQtyDumped(e.target.value);
                      setLossAmt(
                        salvageRateRef.current.value
                          ? (Number(normalRate) -
                              Number(salvageRateRef.current.value)).toFixed(2) *
                              Number(e.target.value)
                          : ""
                      );
                      setSalvageAmt( salvageRateRef.current.value
                        ?  (Number(salvageRateRef.current.value) *
                      Number(e.target.value)).toFixed(2) :"");
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td> Outer size </td>
                <td>
                  <Form.Control
                    value={Outersize}
                    disabled
                   
                  />
                </td>
              </tr>

              <tr>
                <td>Normal Rate (Rs)</td>
                <td>
                  <Form.Control value={normalRate} readOnly />
                </td>
              </tr>
              <tr>
                <td>Salvage Rate (Rs)</td>
                <td>
                  <Form.Control
                    ref={salvageRateRef}
                    required
                    onChange={(e) => {
                      setSalvageRate(e.target.value);
                      setLossAmt(
                        quantityDumpedRef.current.value
                          ? Number(quantityDumpedRef.current.value).toFixed(2)  *
                              (Number(normalRate) - Number(e.target.value)).toFixed(2) 
                          : ""
                      );
                      setSalvageAmt( quantityDumpedRef.current.value
                        ?  (Number(quantityDumpedRef.current.value)  *
                      Number(e.target.value)).toFixed(2):"");
                      
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Salvage Amount (Rs)</td>
                <td>
                  <Form.Control
                    readOnly
                    required
                    onChange={(e) => {
                      setSalvageAmt(e.target.value);
                    }}
                    value={salvageAmt && salvageAmt}
                  />
                  <Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>
                </td>
              </tr>
              <tr>
                <td>Loss Amount (Rs)</td>
                <td>
                  <Form.Control
                    readOnly
                    required
                    onChange={(e) => {
                      setLossAmt(e.target.value);
                    }}
                    value={lossAmt && lossAmt}
                  />
                  <Form.Control.Feedback type="invalid">
                    *Required
                  </Form.Control.Feedback>
                </td>
              </tr>
              <tr>
                <td>Reason</td>
                <td>
                  <Creatable
                    required
                    options={ListOfReasons}
                    onChange={(val) => {
                      setDumpReason(val.value);
                    }}
                  />
                </td>
              </tr>
            </Table>
            <center>
              <Button variant="success" type="submit" ref={btnRef}>
                Add Dump Details{" "}
              </Button>
            </center>
            <br />
          </div>
        )}
      </Form>
    </>
  );
}

export default AddProductDump;
